import Work1 from '../../assets/S1.png';
import Work4 from '../../assets/pharma.png';
import Work3 from '../../assets/EFST (1).png';

const Menu = [
    {
        id : 1,
        image : Work1,
        title : 'Y-Builders',
        category : "BTP"
    },
    {
        id : 3,
        image : Work3,
        title : 'English For The Sahel',
        category : "Projets Developpement"
    },
    {
        id : 4,
        image : Work4,
        title : 'Pharma App',
        category : "Developpement"
    },
   
]

export default Menu